<script lang="ts" setup>
const { confirming, params } = useConfirm();
</script>

<template>
  <UModal
    v-model="confirming"
    :ui="{
      container: 'flex flex-col items-center justify-center',
      base: 'overflow-hidden border border-ht-border-2 self-center flex-col justify-start items-start inline-flex md:w-screen md:h-screen',
      overlay: {
        background: 'bg-ht-overlay-bg-fill-3 dark:bg-ht-overlay-bg-fill-3',
      },
      padding: 'p-0',
      margin: 'md:m-0 sm:m-0',
      width: 'w-[600px] md:max-w-screen',
      height: 'h-fit max-h-[80vh] md:max-h-screen md:h-screen',
      background: 'bg-ht-primary-bg-fill dark:bg-ht-primary-bg-fill',
      rounded: 'rounded-[27px] md:rounded-none',
      shadow: 'shadow-2xl dark:shadow-2xl',
    }"
  >
    <div class="p-6 flex-col justify-start items-start gap-4 inline-flex">
      <h5 class="text-lg font-semibold">{{ params.title }}</h5>
      <p class="text-sm leading-[2] whitespace-pre-wrap">
        {{ params.message }}
      </p>

      <div class="w-full mt-4 justify-end items-start gap-4 inline-flex">
        <HButton @click="confirming = false">Cancel</HButton>
        <HButton
          color="red"
          variant="danger"
          @click="
            params.action();
            confirming = false;
          "
        >
          {{ params.label }}</HButton
        >
      </div>
    </div>
  </UModal>
</template>
